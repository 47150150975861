import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import './font/style.css';

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
