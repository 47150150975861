import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import './style.css';
import logo from './corriere-logo.png';

const Footer = props => (
  <Container fluid className="footer">
    <Container>
      <hr />
      <div style={{ textAlign: 'center', padding: "20px 0" }}>
        <img src={logo} />
      </div>
    </Container>
  </Container>
)

export default Footer;