import React from 'react';
import { Container } from 'semantic-ui-react';
import './style.css';
import logo from './logo.png';

const Header = props => (
  <Container fluid className="header">
    <Container className="logo-container">
      <div>
      <img src={logo} />
      </div>
    </Container>
  </Container>
)

export default Header;