import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';
import './style.css';

const speakersList = [
  {
    title: "Luigi Ripamonti",
    subtitle: "Direttore Corriere Salute"
  },
  {
    title: "Professor Pier Mannuccio Mannucci",
    subtitle: "Ematologo, Irccs Ospedale Maggiore Policlinico; professore emerito di Medicina Interna, Università degli studi di Milano<br/>Membro della commissione Aifa per la valutazione del rischio trombosi associato alla vaccinazione anti Covid 19"
  },
]

const Speakers = props => (
  <Container fluid className="speakers-container">
    <Container className="speakers-container">
      <h2 className="speakers-container-title">
        Relatori
      </h2>
      <div className="speakers-list">
        {speakersList.map(speaker => (
          <div className="speaker-container">
            <div className="titles">
              <div className="speaker-title">
                {speaker.title}
              </div>
              <div className="speaker-subtitle" dangerouslySetInnerHTML={{__html: speaker.subtitle}}>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  </Container>
  )
  
export default Speakers;